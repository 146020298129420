const $ = require('jquery');
window.jQuery = $;
window.$ = $;
require('cookieconsent');


window.addEventListener("load", function () {

    let content;

    $('[href="/data-optout"]').attr('href', 'javascript:gaOptout()');

    if(document.documentElement.lang.match(/^de.*$/)) {
        content = {
            "message": "Um die Webseite optimal zu gestalten und fortlaufend verbessern zu k&ouml;nnen, verwenden wir Cookies. Indem Sie fortfahren, akzeptieren Sie die Verwendung von Cookies. Weitere Infos erhalten Sie in unserer <a href='#!datenschutzerklaerung'>Datenschutzerkl&auml;rung</a>.",
            "dismiss": "Akzeptieren",
            "link": false,
        };
    } else {
        if(window.location.host === 'www.palmerhargreaves.co.uk') {
            content = {
                message: "We use cookies in order to tailor the website in an optimal way and to constantly improve it. You accept the use of cookies by proceeding. Please find further information in our <a href='#!privacy'>Privacy Policy</a>.",
                dismiss: "Accept",
                link: !1
            };
        } else {

            if(window.location.host === 'phands-on.co.uk') {

                content = {
                    message: "We use cookies in order to tailor the website in an optimal way and to constantly improve it. You accept the use of cookies by proceeding. Please find further information in our <a href='#!privacy'>Data Protection Statement</a>.",
                    dismiss: "Accept",
                    link: !1
                };

            } else if ( window.location.host !== 'www.take-measure.de' ) {

                content = {
                    message: "We use cookies in order to tailor the website in an optimal way and to constantly improve it. You accept the use of cookies by proceeding. Please find further information in our <a href='#!data-protection'>Data Protection Statement</a>.",
                    dismiss: "Accept",
                    link: !1
                };

            }
        }
    }

    if(document.documentElement.lang.match(/^de.*$/)) {

    } else {

        if (
            window.location.host != "www.palmerhargreaves.de" &&
            window.location.host != "www.ph-onemagnify.com" &&
            window.location.host != "ph-onemagnify.com" &&
            window.location.host != "www.ph-onemagnify.cn" &&
            window.location.host != "ph-onemagnify.cn"
        ) {

            window.cookieconsent.initialise({
                "palette": {
                    "popup": {
                        "background": "#003c4b",
                        "text": "#ffffff"
                    },
                    "button": {
                        "background": "#ce1141",
                        "text": "#ffffff"
                    }
                },
                "content": content
            })

        }

    }
});

// cookiebot

$( "#ph-cookie-renew" ).on( "click", function() {
    Cookiebot.renew();
});
